// Global Variables


// CMS UI Styles
@import 'c_ui_styles';

// Partial Imports
@import 'c_reset';
@import 'c_site';
// @import 'c_site_sidebar';
@import 'c_default';
@import 'c_insidepages';
@import 'c_slider';
@import 'c_scroll';
@import 'c_fonts';
@import 'c_cmsstyles';

// Mobile / Responsive Imports

@import 'c_meanmenu';
@import 'c_respond';


//Quick links widget
@import 'c_quicklinks';
