// Scroll
.scroll {
    margin: 0 0 30px 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 9px;
    color: #fff;
    width: 100%;
    height: 70px;
    line-height: 2.7em;
}


#ticker-wrapper {
    width: 990px;
    margin: 0 auto;
    height: 70px;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    display: block;
    background: rgba(0, 43, 89, 0.8);
    text-align: right;
    &:before { content: ''; position: absolute; height: 75px; width: 75px; background: url(/i/design/scroll-bg.png) no-repeat; margin: -5px 0 0 0;}
}

.tickercontainer {
    width: 100%;
    height: 70px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: inline-block;
}

.tickercontainer .mask {
    position: relative;
    width: 90%;
    float: right;
    overflow: hidden;
    height: 70px;
    margin: 0 2%;
}

ul.newsticker {
    position: relative;
    width: 99999px !important;
    left: 990px;
    line-height: 70px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 70px;
}

ul.newsticker li {
    float: left;
    margin: 0;
    padding: 0 100px 0 0;
    background: none;
    white-space: nowrap;
    color: #fff;
    font-size: 17px;
}

ul.newsticker li a {
    text-decoration: none;
    color: #bad522;
}

ul.newsticker li a:hover {
    color: #009639;
    text-decoration: underline;
}
