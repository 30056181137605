@font-face {
	font-family: 'Century Gothic';
	src: url('../inc/font-face/CenturyGothic.eot');
	src: url('../inc/font-face/CenturyGothic.eot?#iefix') format('embedded-opentype'),
		url('../inc/font-face/CenturyGothic.woff2') format('woff2'),
		url('../inc/font-face/CenturyGothic.woff') format('woff'),
		url('../inc/font-face/CenturyGothic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('../inc/font-face/CenturyGothic-Bold.eot');
	src: url('../inc/font-face/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
		url('../inc/font-face/CenturyGothic-Bold.woff2') format('woff2'),
		url('../inc/font-face/CenturyGothic-Bold.woff') format('woff'),
		url('../inc/font-face/CenturyGothic-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
