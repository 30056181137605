// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

// BODY
body                {color: #5f6163; font-size: 15px;font-family: 'Century Gothic'; text-align: center; line-height: 1.528571429;padding: 0px; margin: 0; overflow-x:hidden;
									//background: url(/i/design/design.jpg) center top no-repeat; opacity: .5;
}

// LINKS
body a              {color: #999; text-decoration: none;}
body a:hover, body a:focus {text-decoration: underline;}

// LINKED IMAGES
a img, img a, img, p a img 	{border: 0; border-bottom: 0; text-decoration: none; vertical-align: middle; }
a img:hover					{opacity: 0.8}

// HEADINGS
h1,h2,h3,h4,h5,h6	{font-weight: 500;}
h1					{font-size: 48px; margin: 0 0 20px 0; color: #000; line-height:1.2; }
h2					{font-size: 20px; margin: 25px 0 20px 0; color: #000; line-height:1.3; }
h3					{font-size: 15px; margin: 25px 0 10px 0; padding: 5px 10px; background: #333;color: #fff;}
h4					{@extend h3; background-position: 10px center; background-repeat: no-repeat; padding-left: 30px; }
h4.open 			{background-image: url(/i/design/expanding-faq/minus.png); }
h4.closed 			{background-image: url(/i/design/expanding-faq/plus.png); }
h5 					{font-size: 12px; }
h6, small 			{font-size: 10px; }
h1+h2				{margin-top:0; border:0; padding:0; }
h1+p, h2+p, h3+p	{margin-top:0; }

// PARAGRAPHS AND LIST ITEMS
p					{margin: 12px 0; padding: 0; }
li					{margin: 2px 0; padding: 0 0 0 23px; background-image: url(/i/icons/bullet_black.png); background-repeat: no-repeat; }

// FORMS
form 					{margin:0; padding:0; @extend .clearfix; }
fieldset 				{border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
legend 					{border: 0; padding: 0; white-space: normal; *margin-left: -7px; }
button, html input[type="button"], input[type="reset"], input[type="submit"] {-webkit-appearance: button; cursor: pointer; *overflow: visible; /* 4 */ }
button[disabled], html input[disabled] {cursor: default; }
input[type="checkbox"], input[type="radio"] {box-sizing: border-box; padding: 0; *height: 13px; *width: 13px; }
input[type="search"] 	{-webkit-appearance: textfield; box-sizing: content-box; }
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
button::-moz-focus-inner, input::-moz-focus-inner {border: 0; padding: 0; }
textarea 				{resize: vertical; overflow: auto; vertical-align: top; }
label 					{float: left; margin: 10px 0; width: 90px; display: inline;}
form .formfield 	    {margin: 10px; float: left; display: inline; width: 200px; border: 1px solid #CCC; padding: 2px; }
form div			    {margin: 15px 0; display: block; clear: left; vertical-align: top; }
form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] {border: 1px solid #CCC; padding: 4px; width: 300px; margin: 0; background-color: #EFEFEF;}
input[type="text"]:focus, form div textarea:focus, form span textarea:focus, form div input[type="textarea"]:focus, form div input[type="password"]:focus {border-color: #000; }
form div span.label label, form div span.label,span.spacer,span.multiple span {width: 180px; float: left; margin: 0 0 0 0; }
form div span.multiple  {float: left; }
form div span.button    {padding-left: 180px; background: transparent; border: 0; }
form div span.button input	{background: #666; color:#FFF; margin: 0; padding: 8px 10px; font-weight:bold; border: 0; }
form div span.button input:hover {background: #888; }
form div.clear 			{clear: both; padding-top: 5px; }

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper            {margin: 0 auto; text-align: center; }
.wrapper 			{width: 990px; margin: 0 auto; @extend .clearfix; }

// -----------------------------------//
// HEADER
// -----------------------------------//

header       {
	&:before { content: '';  background: url(/i/design/header-grad.png) top center repeat-x; position: absolute; left: 0; top: 0; width: 100%; height: 50%;}
	.wrapper { position: relative;}
   }

header h1 { margin: 0 0 -17px -18px;}


#logobar{text-align: left; padding: 45px 0 0 0;}
img#logo{ margin: auto; display: block;}

.language           { background: url(/i/design/language-ic.png) no-repeat; float: right; width: 30px; height: 30px; overflow: hidden; margin: -85px 0 85px 0;}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//
#FS{position: absolute;right: 41px; top: 54px;}
#FS input {outline: none;color: #fff;}
#FS input[type=search] {-webkit-appearance: textfield;-webkit-box-sizing: content-box;font-family: inherit;font-size: 100%;}
#FS input::-webkit-search-decoration,
#FS input::-webkit-search-cancel-button {display: none;}

#FS input[type=search] {color: transparent;cursor: pointer;background: url(/i/design/search-ic.png) no-repeat 0 center #1d548f;padding:0px;width: 30px;height: 30px;	box-shadow:inset 2px 2px 0 0 rgba(0,0,0,.15);	transition: all .5s;}
#FS input[type=search]:hover {background-color: #1d548f;}

#FS input[type=search]:focus {width: 120px;background-color: #1d548f;padding-left: 32px;color: #fff;cursor: auto;font-weight: 400;
	box-shadow:inset 2px 2px 0 0 rgba(0,0,0,.15);}

#FS input[type=search]:focus:-moz-placeholder {color: #fff;font-weight: 300;-webkit-transition: .3s ease-out; transition: .3s ease-out;}
#FS input[type=search]:focus::-webkit-input-placeholder {color: #fff;font-weight: 300;-webkit-transition: .3s ease-out; transition: .3s ease-out;}
#FS input:-moz-placeholder {color: transparent;-webkit-transition: .3s ease-out; transition: .3s ease-out;}
#FS input::-webkit-input-placeholder {color: transparent;-webkit-transition: .3s ease-out; transition: .3s ease-out;}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//
$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box{display: inline-block; background: #f1f1f1; padding: 5px 15px; font-size: 16px; border-radius: 4px; border: 1px solid #ccc; text-transform: uppercase;}
.search-numbers{text-transform: uppercase; font-weight: bold; font-size: 12px;
	span{background: #f1f1f1; padding: 3px 8px; margin: 0 3px;border-radius: 2px; border: 1px solid #ccc; }
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element   { opacity: 0;}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

body.body_1574 #mainholder { width: 100%; padding: 0; margin: 0 auto 40px;}

#mainholder         {width: 990px; margin: 0px auto; padding: 30px 0 30px 0; clear: both; text-align: left; background: rgba(255, 255, 255, 0.922); @extend .clearfix; }

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

body.body_1574 .Content1Column {	background: url(/i/design/home-bg.png) no-repeat left; height: 550px;
 	#welcome-img { float: right; border-radius: 50%; overflow: hidden; margin: 50px 0 0 0;}
	p.intro { color: #001e48; font-size: 24px; font-weight: bold; text-decoration: underline; margin-top: 41px; line-height: 34px; margin-bottom: 26px;}
	p { width: 397px; float: left; margin: 6px 0 37px 223px; line-height: 24px; }
	a.prospectus-button { background: url(/i/graphics/prospectus.png) no-repeat; padding: 10px 52px 11px 124px; line-height: 24px; display: block; float: right; text-align: center; color: #fff; font-weight: bold; font-size: 18px; margin: -51px 22px 0 0;}
}

//Content 1
.Content1Column     {width: 990px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left; }

//Content 2
.Content2Column     {width: 600px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left; }

//Content 3
.Content3Column     {width: 430px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left; }

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft			{width: 310px; margin: 0; padding: 0 0 0 0; min-height:250px; text-align:left; float: left; }
.ColumnRight		{width: 310px; margin: 0; padding: 0 0 0 0; min-height:250px; text-align:left; float: right; }

.ColumnLeft ul, .ColumnRight ul {margin: 0; padding: 0; list-style: none; }
.ColumnLeft nav ul a:link, .ColumnRight nav ul a:visited, .ColumnRight nav ul a:link, .ColumnRight nav ul a:visited {display: block; }
.ColumnLeft li 		        	{border-bottom: 1px solid #EEE; } // Hack to fix IE/Win's broken rendering of block-level anchors in lists
html > body .ColumnLeft li    	{border-bottom: none; } // Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading            	  { margin: 30px 0 0 0; padding: 0; text-align: center;}
.SideHeading:first-child 	  { margin-top: 0; }

// Heading
.SideHeading h2         	  { display: block;text-align: center;font-size: 24px;padding: 20px 0;margin: 0;}

// List
ul.SideList             	  { margin:0 0 0 0; padding: 0; border: 0; list-style: none; text-align: left;}
ul.SideList li         		  { margin:0 0 0 0; padding: 0; background: none;}

ul.SideList li a       		  { margin: 0; padding: 5px 10px; color: #001e48; font-size: 14px; font-weight: normal;display: block; }
ul.SideList li a:hover  	  { background: #001e48 !important; color: #FFF !important; text-decoration: none;}
ul.SideList li a.child        { background: url(/i/design/lite/bg_nav_li_a_child.png) 20px 12px no-repeat; padding-left: 40px;
	&:hover{background-color: #999;}
}


// Date and Title
ul.SideList li p        { margin: 0; padding: 0; }
ul.SideList p.date      { float: left; width: 55px;height: 55px;text-align: center;font-size: 16px;line-height: 130%; font-weight: bold;
	span{display: block; font-size: 28px;margin: 10px 0 0 0;}
}
ul.SideList p.title     { float: right; width: 70%; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; margin: 5px 10px 0 0;}

// More links
.SideHeading a.more     { font-size: 12px; padding: 0; display: inline-block; color: #fff; font-weight: bold; background: #001f41 url(/i/design/more-ic.png) left no-repeat; height: 34px; line-height: 34px; text-align: center; width: 120px; text-indent: 26px; margin: 15px 7px 0 0;}

//Diary

.diary-popout			{ width: 405px; height: 340px; position: absolute; right: -325px; top: 265px; background: url(/i/design/diary-bg.png) no-repeat; transition: all ease 0.8s;}

.diary-out				{ right: 0px; transition: all ease 0.8s;}


#SideHeadingDiary{ width: 325px; float: right;
	ul.SideList { width: 260px; margin: 26px auto;
		li { margin: 15px 0;
		a { background: #fff; box-shadow: 0px 5px 0px 0px rgba(212, 185, 117, 0.4);}
}
	}

}

//News
#SideHeadingNews{}

//Related pages
#SideHeadingPages{}


// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home{background: rgba(0, 0, 0, .4);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 8000;
	.message-modal{position: absolute;top: calc(50% - 300px);left: calc(50% - 465px - 40px - 10px);width: 930px;height: auto; margin: 0 30px; padding: 20px;background: rgba(255, 255, 255, .9);
		.modal-content{background: rgba(255, 255, 255, .9);background-position: -120px 50px ;box-shadow:inset 0 0 0 1px #fff;display: inline-block;width: 100%;height: 100%;text-align: center;
			.modal-text{display: block; padding: 45px;
				p{margin: 0;}
			}
			a.close-modal{position: absolute;top: 31px;right: 31px;}
		}
	}
}

.hide-modal{display: none !important;}



// -----------------------------------//
// FOOTER
// -----------------------------------//

footer                { clear: both; background: rgba(0, 22, 47, 0.922); height: 210px; overflow: hidden;}
footer > .wrapper     { text-align: left; background: url(/i/design/footer-bg.png) no-repeat 733px 40px; height: 210px; overflow: hidden;}
footer > .wrapper p   {  color: #fff; font-size: 14px; letter-spacing: 0.02em;}
footer > .wrapper a   { color: #d4b975; }

p.address							{ margin: 55px 0 0 0;
	span { font-weight: bold;}
}

p.contact							{ margin: -105px 0 0 250px;}

#credit        { margin: 0; padding: 0; float: right; text-align:right;}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: darkblue;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

$blockquote-background-color: #EFEFEF;
$blockquote-border-color: #EBEBEB;
$blockquote-color: #333;
$blockquote-speech-mark-color: #999;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content { background: none; }


// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast         { position: fixed; top: 0; left: 0; width: 100%; height: 40px; border-bottom: 1px solid #D4C790; font-family: sans-serif; font-size: 12px; font-weight: bold; line-height: 40px; color: #6d6647; text-align: center; background: #FAF4AF; }
#browserblast a       { color: green; text-decoration: underline; }
.unsupported-browser  { margin-top: 41px; }

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile      { width:100%; margin: 0; padding: 20px 0; background:#EFEFEF; border-top:1px solid #EBEBEB; border-bottom:1px solid #EBEBEB; text-align:center; color:#777; font-family: sans-serif; font-size: 12px; display:block; clear:both; }
#switch-mobile a    { text-decoration: underline; }
